@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 0.5px var(--dino-blue);
    -webkit-text-fill-color: transparent;
  }

  .challengePage {
    scroll-behavior: smooth !important;
    scroll-padding-top: 3rem;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --dino-blue: #3069d8;
    --dino-blue-dark: #3d72db;
    --dino-black: #252525;
    --dino-yellow: #faff00;
    --dino-yellow-dark: #ebcd27;
    --dino-orange: #e9a800;
    --dino-orange-dark: #e1570d;
    --dino-gray: #efefef;
    --dino-yellow-200: #ffce5f;
    /* dino-bucks */
    --bucks-highlight-bg: #feeba2;
    --bucks-highlight-text: #655209;
  }

  .testimonials .swiper-button-prev {
    @apply ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-white p-1.5 md:ml-0;
  }
}

@layer base {
  body {
    @apply scroll-smooth font-tofinoText !antialiased;
    @apply w-full overflow-x-hidden;
  }
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.profile-page {
  display: grid;
  grid-template-areas:
    'gird-refer'
    'gird-profile'
    'gird-orders'
    'gird-address'
    'gird-review'
    'gird-contact-us'
    'gird-logout';

  gap: 1rem;

  @screen md {
    grid-template-areas:
      'gird-profile     gird-orders gird-orders'
      'gird-refer       gird-orders gird-orders'
      'gird-refer       gird-contact-us gird-review'
      'gird-refer       gird-address gird-logout '
      'gird-refer       . . ';

    grid-template-columns: 1.1fr 1fr 1fr;
  }
}
.profile-page .gird-profile {
  grid-area: gird-profile;
}
.profile-page .gird-orders {
  grid-area: gird-orders;
}
.profile-page .gird-refer {
  grid-area: gird-refer;
}
.profile-page .gird-logout {
  grid-area: gird-logout;
}
.profile-page .gird-address {
  grid-area: gird-address;
}
.profile-page .gird-review {
  grid-area: gird-review;
}
.profile-page .gird-contact-us {
  grid-area: gird-contact-us;
}

/* order-list */
.order-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@screen md {
  .order-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* gird-2 */
.gird-list-2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@screen md {
  .gird-list-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.load-9 .spinner {
  animation: loadingI 2s linear infinite;
}
.load-9 .bubble-1,
.load-9 .bubble-2 {
  animation: bounce 2s ease-in-out infinite;
}
.load-9 .bubble-2 {
  animation-delay: -1s;
}

@keyframes loadingI {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  width: 65px;
  height: 65px;
  margin: 0 auto;
}

.bubble-1,
.bubble-2 {
  position: absolute;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: var(--dino-blue);
}

.bubble-2 {
  top: auto;
  bottom: 0;
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@layer components {
  .container {
    @apply mx-auto w-full max-w-7xl px-5 md:px-8;
  }

  .bg-square-pattern {
    background-color: #252525;
    opacity: 1;
    background-image: linear-gradient(#323232 1px, transparent 1px),
      linear-gradient(to right, #323232 1px, #252525 1px);
    background-size: 60px 60px;
  }

  .product-page {
    scroll-behavior: smooth;
  }
}

@layer utilities {
  .flexColToRow {
    @apply flex items-center justify-center;
    @apply flex-col md:flex-row;
  }

  .flexRowToCol {
    @apply flex items-center justify-center;
    @apply flex-row md:flex-col;
  }

  .flex-in-row {
    @apply flex items-center justify-center;
  }

  html {
    height: 100%;
  }

  .min-h-content {
    min-height: calc(100vh - 10rem);
  }

  .shadow-top {
    box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75);
  }

  .shadow-section-end {
    z-index: -10;
    box-shadow: inset 0px -11px 8px -10px #161515;
  }

  .place-item-at-ends {
    @apply flex items-center justify-between;
  }

  .strikediag {
    display: inline-block;
    position: relative;
    padding: 0 0.15em;
  }
  .strikediag::before {
    content: '';
    position: absolute;
    left: -0.1em;
    right: -0.1em;
    top: 0.38em;
    bottom: 0.38em;
    background: linear-gradient(
      to left top,
      transparent 45.5%,
      currentColor 47.5%,
      currentColor 52.5%,
      transparent 54.5%
    );
    pointer-events: none;
  }

  .invalid-input .help-text {
    @apply text-red-500 opacity-100;
  }

  .invalid-btn {
    @apply bg-red-500 text-white hover:!scale-100;
  }

  /* .stack; */

  .input-profile-details {
    @apply bg-white shadow-sm;
  }

  .shadow-top {
    box-shadow: rgb(0 0 0 / 12%) 0px -6px 8px -4px;
  }

  .text {
    @apply text-[#2d2d2d]   md:text-lg;
  }
  .policy b {
    @apply font-bold text-dino-blue;
  }
  .policy span {
    @apply text-[#2d2d2d];
  }
  .highlight {
    @apply font-bold text-[#2d2d2d];
  }

  .heading {
    @apply font-tofinoWide text-3xl font-bold  leading-tight text-[#222222] md:text-5xl;
  }

  .heading-highlight {
    @apply text-[1rem] font-medium  leading-tight text-dino-blue md:text-xl md:tracking-wider;
  }

  .heading-2 {
    @apply mb-2 mt-10 font-tofinoWide text-xl  leading-tight text-[#222222] md:mb-4 md:mt-14 md:text-3xl;
  }

  input[type='date'] {
    min-height: 1.8rem;
    background: #fff;
  }

  .dino-white-card {
    @apply relative rounded-2xl border border-gray-300 bg-white px-4 py-4 shadow-md;
  }

  .ingredient-container ul {
    @apply ml-4 mt-1 list-disc;
  }

  .scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .button-press-animation {
    @apply scale-100 transform cursor-pointer select-none transition-all duration-200 hover:scale-105 active:scale-105;
  }

  .referral-text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }
}
.anchortags {
  text-decoration: unset !important;
}

.full-bleed {
  width: 100vw;
  max-width: 100vw;
  margin: 0 calc(-50vw + 50%);
}

@layer utilities {
  .fs-xs {
    @apply text-xs md:text-sm;
  }

  .fs-sm {
    @apply text-sm md:text-base;
  }

  .fs-base {
    @apply text-base md:text-lg;
  }

  .fs-lg {
    @apply text-lg md:text-xl;
  }

  .fs-xl {
    @apply text-xl md:text-2xl;
  }

  .heading-fs-xs {
    @apply text-base sm:text-lg md:text-xl;
  }

  .heading-fs-sm {
    @apply text-lg sm:text-xl md:text-2xl;
  }

  .heading-fs-base {
    @apply text-xl sm:text-2xl md:text-3xl;
  }

  .heading-fs-lg {
    @apply text-2xl sm:text-3xl md:text-4xl;
  }

  .heading-fs-xl {
    @apply text-3xl sm:text-4xl md:text-5xl;
  }

  .heading-fs-2xl {
    @apply text-4xl sm:text-5xl md:text-6xl;
  }

  .last-divide > *:nth-child(n + 2):nth-last-child(1) {
    @apply border-t border-gray-300;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
